import { DepotStatus } from "types";

export const onboardingRequiredStatuses: DepotStatus[] = [
  "RECEIVED",
  "APPROVED",
  "COUNSELING_COMPLETED",
  "SURVEY_SCHEDULED",
  "SURVEY_IN_PROGRESS",
  "SURVEY_COMPLETED",
  "PLANNED",
  "PACK_CREW_DISPATCHED",
];
