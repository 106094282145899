import { translationKeys } from "services";

export const validCounselingStatuses = [
  "WELCOME",
  "ENTITLEMENTS_PREFERENCES",
  "ENTITLEMENTS_FORMS",
  "ADDRESSES_ORIGIN",
  "ADDRESSES_DESTINATION",
  "ADDRESSES_EXTRA_PICKUP",
  "ADDRESSES_EXTRA_DELIVERY",
  "STORAGE_IN_TRANSIT",
  "MOVE_DATES",
  "SURVEY_SCHEDULE_TYPE",
  "SURVEY_SCHEDULE_TIME",
  "COMPLETE",
  "UNKNOWN",
] as const;

export type CounselingStatusId = (typeof validCounselingStatuses)[number];

export interface CounselingStatus {
  id: CounselingStatusId;
  value: number;
  label: translationKeys;
  display: boolean;
}

export type CounselingStatusToPathMap = {
  [s in CounselingStatusId]: string;
};

export const counselingStatusToPathMap: CounselingStatusToPathMap = {
  WELCOME: "/counseling/welcome",
  ENTITLEMENTS_PREFERENCES: "/counseling/entitlements/preferences",
  ENTITLEMENTS_FORMS: "/counseling/entitlements/forms",
  ADDRESSES_ORIGIN: "/counseling/addresses/origin",
  ADDRESSES_DESTINATION: "/counseling/addresses/destination",
  ADDRESSES_EXTRA_PICKUP: "/counseling/addresses/extra-pickup",
  ADDRESSES_EXTRA_DELIVERY: "/counseling/addresses/extra-delivery",
  STORAGE_IN_TRANSIT: "/counseling/addresses/storage",
  MOVE_DATES: "/counseling/move-dates",
  SURVEY_SCHEDULE_TYPE: "/counseling/survey/type",
  SURVEY_SCHEDULE_TIME: "/counseling/survey/schedule",
  COMPLETE: "/",
  UNKNOWN: "/counseling/welcome",
};
