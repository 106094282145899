import { ShipmentType } from "../__generated__/types";

export const shipmentTypeLabelMap: { [key in ShipmentType]: string } = {
  BOAT_HAUL_AWAY: "Boat Haul Away",
  BOAT_TOW_AWAY: "Boat Tow Away",
  HHG: "Household Goods",
  HHG_INTO_NTS_DOMESTIC: "Household Goods (NTS)",
  HHG_LONGHAUL_DOMESTIC: "Household Goods (Longhaul Domestic)",
  HHG_OUTOF_NTS_DOMESTIC: "Household Goods (NTS Release)",
  HHG_SHORTHAUL_DOMESTIC: "Household Goods (Shorthaul Domestic)",
  INTERNATIONAL_HHG: "Household Goods",
  INTERNATIONAL_UB: "Unaccompanied Baggage",
  MOTORHOME: "Motor Home",
  PPM: "Personally Procured Move",
  UB: "Unaccompanied Baggage",
};

export const getShipmentTypeLabel = (shipmentType: ShipmentType): string => {
  return shipmentTypeLabelMap[shipmentType];
}